@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-root  {
  font-family: 'Poppins', sans-serif !important;
}
